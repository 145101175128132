.complimentary_colm_height{
  min-height: 30px;
}

.includeGWP{
  position: relative;
  cursor: pointer;
  text-align: left;
  pointer-events: auto;
  padding-bottom: 10px;
}

.includeGWPIcon{
  font-size: 14px;
}

.includeGWPHeight{
  min-height: 25px;
}

.includeGWPDesc{
  position: absolute;
  z-index: 1;
  font-size: 9px;
  bottom: 100%;
  left: 0%;
  width: 100%;
  border: 1px solid;
  background-color: white;
  color: #c4601d;
  text-align: center;
  border-radius: 6px;
  padding: 10px
  
}

.includeGWPDesc li{
  list-style: circle;
  list-style-position: inside;
  text-align: left;
}

.productitem, .categoryProductItem {
  white-space: normal;
  display: inline-block;
  width: 100%;
  margin-bottom:20px;
  position: relative;
}
.productitem span, .categoryProductItem span {
  background: #eeeeee;
  display: block;
  width: 100%;
  padding: 5px;
  margin-bottom: 12px;
  border-radius: 0px;
}
.productitem h3, .categoryProductItem h3 {
  font-size: 22px;
  color: #222222;
  text-transform: capitalize;
  font-weight: 500;
  line-height: 1.3;
  margin-bottom: 10px;
  min-height: 60px !important;
  overflow: hidden; 
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical
}
.productitem p, .categoryProductItem p {
  font-size: 14px;
  color: #000;
  margin-bottom: 8px;
  min-height: 63px;
}
.productitem h4, .categoryProductItem h4 {
  font-size: 20px;
  font-family: 'Barlow';
  font-weight: 500;
  line-height: 1.1;
  padding-bottom: 15px;
  color: #222222;
}
.priceParent h4{
  font-size: 15px;
}
.productitem h4 small, .categoryProductItem h4 small {
  font-size: 16px;
}
.productitem p a, .categoryProductItem p a {
  font-weight: 600;
  display: block;
  color: #c4601d;
  text-transform: uppercase;
  letter-spacing: 1.25px;
  font-size: 12px;
  padding-top: 4px;
}
.btnbag button {
  width: 100%;
  border: none;
  text-align: center;
  letter-spacing: 0.5px;
  font-size: 1rem;
  line-height: 24px;
  color: #222222;
  border: 1px solid #3e4245;
  text-decoration: none solid rgb(255, 255, 255);
  /* box-shadow: 0px 0px 16px rgb(82 53 40 / 16%); */
  background: transparent;
  display: block;
  /* border-radius: 5px; */
  /* text-transform: uppercase; */
  font-weight: 500;
  padding: 9px;
  font-family: 'Barlow';
}
.btnbag button:hover{
  border: 1px solid #ad3132;
}

.view_size_colm {
  position: absolute;
  bottom: 100%;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  box-shadow: 0px -3px 6px rgb(82 53 40 / 16%);
  background: #fff;
  width: 100%;
  padding: 10px;
  padding-bottom: 5px;    z-index: 1;
}
.view_size_colm h6 {
  font-size: 13px;
  color: #000;
  float: left;
}
.view_size_colm .col .close {
  background: none;
  font-size: 0;
  padding: 0;
  border-radius: 0;
  letter-spacing: initial;
  line-height: initial;
  width: 11px;
  float: right;
}
.view_size_colm .col {
  border-bottom: 1px solid #ecd8ce;
  display: inline-block;
  width: 100%;
  padding-bottom: 8px;
  margin-bottom: 8px;
  padding-top: 2px;
}
.view_size_colm table tr td {
  font-size: 13px;
  letter-spacing: 0.25px;
  padding-bottom: 10px;
}
.view_size_colm table tr td input {
  margin-right: 6px;
  height: 11px;
}
.view_size_colm table tr td:last-child {
  text-align: right;
}
.view_size_colm table tr td small {
  font-size: 9px;
}
.col-8 {
  padding: 0 7px;
}
.btnbag {
  position: relative;
}
.outofstock{
 width: 100%;
 border: none;
 text-align: center;
 letter-spacing: 0.5px;
 font-size: 12px;
 line-height: 24px;
 color: #ffffff;
 text-decoration: none solid rgb(255, 255, 255);
 box-shadow: 0px 0px 16px rgb(82 53 40 / 16%);
 background: #ad3132;
 display: block;
 border-radius: 0px;
 text-transform: uppercase;
 font-weight: 600;
 padding: 9px;
 opacity: .7;
}

.complimentary_colm{width: 100%;color: #AD3132;font-size: 12px; letter-spacing: 0.25px; padding-bottom: 10px; display: inline-block; font-weight: 600; }
.video_icon{position: absolute; z-index: 1; left: 12px;top: 12px;}
.video_icon a{ display: block; cursor: pointer;}
.category_star {font-size: 10px;text-transform: uppercase;letter-spacing: 1.5px;    display: flow-root;
 padding-bottom:2px;    min-height: 19px;}
.category_star div {  float: right;color:#575757; font-family: 'Barlow'; font-weight: 500; font-size: 11px;}
.category_star div div{ float: none;}
.category_star h6{    letter-spacing: 1.5px;font-size: 11px;float: left;font-weight: 500; font-family: 'Barlow'; color: #575757;}
.category_row{ position: relative; top: -2px;}
.cancelbutton { position: absolute; top: 0px; right: 0; background: #fff;padding: 3px 3px;
line-height: 14px; margin-top: 0px;cursor: pointer;}
/* .lifestyle_img span{padding:0; overflow: hidden;} */
.btnbag button img{vertical-align: text-bottom;margin-right:5px; position: relative; }
.cutoffprice{  padding-left: 12px !important;
color: rgb(62 62 62 / 50%);
font-size: 19px;
text-decoration: line-through !important;
line-height: 1.1;
height: unset !important;
display: inline !important;
background: unset !important;}
.dashboard-orders-popup .modal-dialog {width: 100%;margin: 0;}
.modal-input input{ border: 0;width: 100%;    display: inline-block;border-bottom: 1px solid #f0ddd1; font-size: 14px;
padding: 4px 0;}
.modal-input label{font-size: 12px;color: #c4601d;padding-bottom: 5px;width: 100%; position: relative; display: block;}
.modal-input { display: inline-block; padding-bottom: 25px; width: 100%;}
.modal-input-btn{    display: inline-block;width: 100%;margin-top: 30px;}
.modal-input-btn button{background-color: #c4601d;
width: 100%;color: #fff;display: block;padding: 13px 0;border-radius: 5px;-webkit-box-shadow: 0 0 16px rgb(82 53 40 / 16%);box-shadow: 0 0 16px rgb(82 53 40 / 16%);text-transform: uppercase;letter-spacing: 1.25px;font-size: 12px;text-align: center;font-weight: 600;border: 0;}.btn-close{    position: absolute;right: 20px;top: 20px;}
.dashboard-orders-popup .modal-dialog { margin: 0;}
.dashboard-orders-popup .modal-content { border: 0; border-radius: 0;    border-top-left-radius: 10px;    border-top-right-radius: 10px;}
.modal.fade.dashboard-orders-popup .modal-dialog {transform: translate(0, 50px); position: absolute; bottom: 0;}
.modal.fade.dashboard-orders-popup.show .modal-dialog { transform: none;}
.dashboard-orders-popup h5.modal-title {font-size: 28px;font-family: Cormorant Garamond; padding-top: 30px; line-height: 1.3;font-weight: 500;}
.dashboard-orders-popup .modal-header{ border: 0; padding:20px 30px 0;    align-items: inherit;}
.dashboard-orders-popup .modal-body{ padding:20px 30px;}
.dashboard-orders-popup{ width: 435px; max-width: 100%;position: relative; height: 100%;}
.dashboard-orders-popup input:-webkit-autofill,  .dashboard-orders-popup select:-webkit-autofill { -webkit-box-shadow: 0 0 0px 1000px white inset;
box-shadow: 0 0 0px 1000px white inset;}
.invalid-feedback{ padding-top:10px; font-size: 13px; letter-spacing: 0.25px;}
.view_size_colm .cutoffprice{    font-size: 12px;    padding-left: 10px !important;}
@media (min-width: 992px){
  .includeGWPDesc{
    padding: 5px 0px;
  }
 .productitem h4 small, .categoryProductItem h4 small {font-size: 23px;}
 .productitem, .categoryProductItem{ margin-bottom: 30px;}
 .productitem span, .categoryProductItem span{ height: 200px;    margin-bottom: 18px; position: relative; display: flex;
width: 100%;background: #fff; overflow: hidden; text-align: center;justify-content: center;
padding:12px 0;}
.productitem span img, .categoryProductItem span img{    transition: all 0.35s ease-out;}
.productitem h3, .categoryProductItem h3 {min-height: 60px !important; letter-spacing: 0.15px; font-size: 20px; font-weight: 500; 
 line-height: 1.4;padding-bottom: 0; margin-bottom: 6px; font-family: 'Bell MT'; color: #222222;}
 .productitem h4, .categoryProductItem h4 { font-size: 23px; padding-bottom: 20px; font-weight: 400; color: #222222; font-family: 'Barlow';}
 .productitem h4 label, .categoryProductItem h4 label {
  font-size: 22px;
 }
 .btnbag button { padding: 11px;    opacity: 0;visibility: hidden;}
 .productitem:hover .btnbag button { padding: 11px;    opacity: 1;visibility:visible;}
 .categoryProductItem .btnbag button { padding: 11px;    opacity: 1;visibility:visible;}
 .productitem:hover svg{opacity: 1;visibility:visible;}
 .categoryProductItem svg{opacity: 1;visibility:visible;}
 .productitem p, .categoryProductItem p{      
   min-height: 55px;    text-transform: capitalize; font-size: 14px; letter-spacing: 0.5px;line-height: 1.9; margin-bottom: 10px;
   overflow: hidden;
   text-overflow: ellipsis;
   display: -webkit-box;
   -webkit-line-clamp: 2;
   -webkit-box-orient: vertical;
   font-family: 'Barlow';
   color: #575757;
  }
 .productitem:hover  a span img, .categoryProductItem:hover a span img {  transform: scale(1.1);}
 .view_size_colm .col .close{ cursor: pointer;}
 .col-8 { padding: 0 12px;    width: 33.3333333333%;}
 .outofstock{ padding: 11px; opacity: 0; visibility: hidden;}
 .productitem:hover .outofstock { padding: 11px;    opacity:.7;visibility:visible;}
 .categoryProductItem .outofstock { padding: 11px;    opacity:.7;visibility:visible;}
 .video_icon{left: 15px;top: 15px;}
 .lifestyle_img span{padding:0;}
 body .cutoffprice small{ font-size: 19px;}
}
.view_size_colm i { color:#575757;     font-size: 11px; display: inline;padding-left: 5px;}
.productitem span img, .categoryProductItem span img{    object-fit: contain; display: block;}
@media (max-width:767px){
  .btnbag button {font-size: 1rem;}
 .view_size_colm i{ display: inline-block;}
 .dashboard-orders-popup{ width: 100%;    position: absolute; background-color: #fff; bottom: 0; border-top-left-radius: 10px;
   border-top-right-radius: 10px;max-width: 100%;height: initial;}
 .cutoffprice{ font-size: 14px;}
 .view_size_colm .cutoffprice{ padding-left: 0 !important; display: block !important;}
 .view_size_colm table tr td{vertical-align: middle;}   
 .dashboard-orders-popup-notify{ width: 100%;}
 .dashboard-orders-popup-notify .dashboard-orders-popup h5.modal-title{ padding-top: 0;    font-size: 22px;}
 .dashboard-orders-popup-notify .dashboard-orders-popup .modal-header{padding: 12px 15px 12px;    border-bottom: 1px solid #f6e4db;}
 .dashboard-orders-popup-notify .modal-input-btn button{top: 16px;}
 .dashboard-orders-popup-notify .dashboard-orders-popup .modal-body{ padding:20px 15px 0;}
 .dashboard-orders-popup-notify .modal-input{    padding-bottom: 15px;}
 .dashboard-orders-popup-notify .modal-input-btn {    margin-top: 10px;}
}